import {Component, OnDestroy, OnInit} from '@angular/core';
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {ActivatedRoute} from "@angular/router";
import {ExposedTriggerService} from "./services/exposed-trigger-service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-exposed-trigger',
  templateUrl: './exposed-trigger.component.html',
  styleUrl: './exposed-trigger.component.scss',
  providers: [ExposedTriggerService],
})
export class ExposedTriggerComponent implements OnInit, OnDestroy{
  latestImage: SafeUrl | undefined;
  triggerUuid: string | undefined;
  exposedToken: string | undefined;
  imageFetchSub: Subscription | undefined;

  constructor(
    private activeRoute: ActivatedRoute,
    private exposedTriggerService: ExposedTriggerService,
    private sanitizer: DomSanitizer,
  ) {
  }

  ngOnInit() {
    this.triggerUuid = this.activeRoute.snapshot.params['triggerUuid'];
    this.exposedToken = this.activeRoute.snapshot.params['exposedToken'];
    this.loadImage();
  }

  loadImage() {
    if (!this.triggerUuid || !this.exposedToken)
      return;
    this.imageFetchSub?.unsubscribe();
    this.imageFetchSub = this.exposedTriggerService.getLatestTriggerImage(this.triggerUuid, this.exposedToken).subscribe(
      (response) => {
        this.latestImage = this.sanitizer.bypassSecurityTrustUrl(
          URL.createObjectURL(response)
        );

        setTimeout((e: any) => {
          this.loadImage();
        }, 60000)
      }
    )
  }

  ngOnDestroy() {}
}
