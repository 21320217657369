<p-card>
  <p-header>
    <h2>Authenticator</h2>
  </p-header>
  <p-footer>
    <p-button (click)="verifyOtp()" label="Continue"></p-button>
  </p-footer>
  <p class="split-text">Please enter the code generated by your authenticator app</p>
  <p-floatlabel variant="on">
    <input (keydown.enter)="verifyOtp()" [(ngModel)]="otp" autocomplete="off" id="otp-input" pInputText type="text">
    <label for="otp-input">Enter code</label>
  </p-floatlabel>
</p-card>
