<p-card [ngClass]="'title-center'" header="Password reset">
  <div (keydown.enter)="requestPassword()" [formGroup]="email">
    <p-floatlabel class="input" variant="on">
      <input
        [email]="true"
        [value]="email"
        formControlName="email"
        id="email-input"
        pInputText
      />
      <label for="email-input" i18n>Email address</label>
    </p-floatlabel>
    <div class="text-center">
      <p-button (click)="requestPassword()" class="requestButton" i18n>Request password reset</p-button>
    </div>
    <a i18n class="back-to-login" routerLink="/login">Back to login</a>
  </div>
</p-card>
