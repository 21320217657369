import {Injectable} from "@angular/core";
import {BaseCalls} from "../../../../objects/BaseCalls";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class ExposedTriggerService extends BaseCalls {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  getLatestTriggerImage(triggerUuid: string, exposedToken: string) {
    return this.doCall('/file_stream/exposed_trigger', {
      params: [
        {param: 'trigger_uuid', value: triggerUuid},
        {param: 'exposed_token', value: exposedToken}
      ],
      responseType: 'blob'
    });
  }
}
