<p-card [ngClass]="'title-center'" header="Password Reset">
  <div (keydown.enter)="savePassword()" [formGroup]="password">
    <p-floatlabel class="input" variant="on">
      <p-password
        class="input"
        formControlName="password"
        id="password-input"
        aria-describedby="password-help"
        [maxLength]="50"
        [toggleMask]="true"
      />
      <label for="password-input" i18n>Password</label>
    </p-floatlabel>
    <p-floatlabel class="input" variant="on">
      <p-password
        class="input"
        formControlName="repeatPassword"
        id="repeat-password-input"
        [maxLength]="50"
        [feedback]="false"
        [toggleMask]="true"
      />
      <label for="repeat-password-input" i18n>Repeat password</label>
    </p-floatlabel>
    <small id="password-help">Password must be at least 8 characters</small>
  </div>
  <div class="text-center">
    <p-button (click)="savePassword()" class="requestButton" i18n>Reset password</p-button>
  </div>
  <a i18n routerLink="/login">Back to login</a>
</p-card>
